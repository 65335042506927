exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-categories-jsx": () => import("./../../../src/templates/categories.jsx" /* webpackChunkName: "component---src-templates-categories-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-news-post-jsx": () => import("./../../../src/templates/news-post.jsx" /* webpackChunkName: "component---src-templates-news-post-jsx" */),
  "component---src-templates-products-post-jsx": () => import("./../../../src/templates/products-post.jsx" /* webpackChunkName: "component---src-templates-products-post-jsx" */)
}

