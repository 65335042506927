import React from 'react';

export const WebContext = React.createContext('it');

export default function WebContextProvider({ children }) {
    const [currentLanguage, setCurrentLanguage] = React.useState('it');
    /*
    React.useEffect(()=>{
        setCurrentLanguage(()=> {
            let currLang = localStorage.getItem("currentLanguage");
            console.debug("first load", currLang);
            if (currLang) {
                if(JSON.parse(currLang) === 'it') {
                    return 'it';
                } else {
                    return 'en-US';
                }
            } else {
                return 'it';
            }
        });
    },[]);
    */
    /*
    React.useEffect(()=>{
        console.debug("set item", currentLanguage);
        localStorage.setItem("currentLanguage", JSON.stringify(currentLanguage));
    },[currentLanguage]);
    */

    return (
        <WebContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
            {children}
        </WebContext.Provider>
    )
}
